import React, { useState } from "react"
import {
  Flex,
  Input,
  Checkbox,
  Spinner,
  InputProps,
  Box,
  Select,
} from "@chakra-ui/core"
import { useForm, Controller } from "react-hook-form"
import { CustomerAddress } from "redux/types/UserTypes"
import { useSelector, useDispatch } from "react-redux"
import { BaseRootState, BaseDispatch } from "redux/store"

import { Button } from "../Buttons"
import { bp } from "../../utils/MediaQueries"
import Body from "../typography/Body"
import { countryList } from "../../utils/addresses"

interface FormType extends CustomerAddress {
  setDefault: boolean
}
export interface AddressFormProps {
  addressId?: string
  onSubmitComplete?: VoidFunction
}

const AddressForm: React.FC<AddressFormProps> = ({
  addressId,
  onSubmitComplete = () => {},
  ...props
}) => {
  const dispatch = useDispatch<BaseDispatch>()
  const { addresses, defaultAddress, shipsToCountries } = useSelector(
    (state: BaseRootState) => state.user
  )
  const previousAddress = addresses?.find((address) => address.id === addressId)

  const { register, handleSubmit, reset, control, errors } = useForm<FormType>({
    reValidateMode: "onSubmit",
    defaultValues: {
      firstName: previousAddress?.firstName ?? "",
      lastName: previousAddress?.lastName ?? "",
      address1: previousAddress?.address1,
      address2: previousAddress?.address2,
      zip: previousAddress?.zip,
      city: previousAddress?.city ?? "",
      country: previousAddress?.country ?? shipsToCountries[0],
      province: previousAddress?.province ?? "",
      company: previousAddress?.company ?? "",
      setDefault: previousAddress?.id === defaultAddress?.id,
    },
  })
  const [error, setError] = useState<string | null>(null)

  React.useEffect(() => {
    if (addressId) {
      const previousAddress = addresses?.find(
        (address) => address.id === addressId
      )
      if (previousAddress) {
        reset({
          firstName: previousAddress.firstName ?? "",
          lastName: previousAddress.lastName ?? "",
          address1: previousAddress.address1,
          address2: previousAddress.address2,
          zip: previousAddress.zip,
          city: previousAddress.city ?? "",
          country: previousAddress.country ?? shipsToCountries[0],
          province: previousAddress.province ?? "",
          company: previousAddress.company ?? "",
          setDefault: previousAddress.id === defaultAddress?.id,
        })
      }
    } else {
      reset()
    }
  }, [addressId])

  const [loading, setLoading] = React.useState<boolean>(false)

  const onSubmit = (values: FormType) => {
    setLoading(true)
    if (addressId === undefined) {
      // @ts-ignore
      dispatch.user
        .addAddress(values)
        // @ts-ignore
        .then((response) => {
          setLoading(false)
          if (response.success) {
            onSubmitComplete()
          } else {
            setError(
              response.error || "Failed to add address. Please try again"
            )
          }
        })
        .catch((err) => {
          setError(err.message || "Failed to add address. Please try again")
          setLoading(false)
        })
    } else {
      // @ts-ignore
      dispatch.user
        .updateAddress({ ...values, id: addressId })
        // @ts-ignore
        .then((response) => {
          setLoading(false)
          if (response.success) {
            onSubmitComplete()
          } else {
            setError(
              response.error || "Failed to update address. Please try again"
            )
          }
        })
        .catch((err) => {
          setError(err.message || "Failed to update address. Please try again")
          setLoading(false)
        })
    }
  }

  const ControllerInput = (inputProps: InputProps = {}) =>
    function ControllerInput({ onChange, onBlur, value, name }) {
      return (
        <Input
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          w={["20.25rem", null, null, null, "23rem"]}
          mb="0.5rem"
          outline={errors[name] ? "sunrise" : ""}
          {...inputProps}
        />
      )
    }

  return (
    <Flex>
      <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "0 5rem" }}>
        <Flex
          flexDir={bp("column", "row")}
          justifyContent={["center", null, null, null, "space-between"]}
          gridColumnGap="3.75rem"
        >
          <Box>
            <Controller
              name="firstName"
              control={control}
              render={ControllerInput({
                ref: register({ required: true }),
                placeholder: "First Name",
                isRequired: true,
              })}
            />
            <Controller
              name="lastName"
              control={control}
              render={ControllerInput({
                ref: register({ required: true }),
                placeholder: "Last Name",
                isRequired: true,
              })}
            />
            <Controller
              name="company"
              control={control}
              render={ControllerInput({
                ref: register({ required: false }),
                placeholder: "Company (Optional)",
              })}
            />
            <Controller
              name="address1"
              control={control}
              render={ControllerInput({
                ref: register({ required: true }),
                placeholder: "Address Line 1",
                isRequired: true,
              })}
            />
            <Controller
              name="address2"
              control={control}
              render={ControllerInput({
                ref: register({ required: false }),
                placeholder: "Address Line 2 (Optional)",
              })}
            />
          </Box>
          <Box>
            <Controller
              name="city"
              control={control}
              render={ControllerInput({
                ref: register({ required: true }),
                placeholder: "City",
                isRequired: true,
              })}
            />
            <Controller
              name="country"
              control={control}
              render={({ onChange, onBlur, value, name }) => {
                return (
                  <Select
                    ref={register({ required: true })}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    w={["20.25rem", null, null, null, "23rem"]}
                    mb="0.5rem"
                    outline={errors[name] ? "sunrise" : ""}
                  >
                    {shipsToCountries.map((country, index) => (
                      <option key={country} value={country}>
                        {countryList[country]}
                      </option>
                    ))}
                  </Select>
                )
              }}
            />
            <Controller
              name="province"
              control={control}
              render={ControllerInput({
                ref: register({ required: true }),
                placeholder: "State or province",
                isRequired: true,
              })}
            />
            <Controller
              name="zip"
              control={control}
              render={ControllerInput({
                ref: register({ required: true }),
                placeholder: "Zip",
                isRequired: true,
              })}
            />
          </Box>
        </Flex>
        <Flex alignItems="center" w="100%" mt="1.125rem" flexDir="column">
          <Checkbox name="setDefault" ref={register()}>
            Set as your default
          </Checkbox>
          <Button type="submit" mt="3rem">
            Save
          </Button>
          {error && (
            <Body mt="0.75rem" color="sunrise">
              {error}
            </Body>
          )}
        </Flex>
      </form>
      <Flex
        position="absolute"
        top={0}
        left={0}
        zIndex={1001}
        bg="#ffffff88"
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="center"
        display={loading ? "flex" : "none"}
      >
        <Spinner size="lg" color="primary" />
      </Flex>
    </Flex>
  )
}

export default AddressForm
