import React from "react"
import { Flex, Box, PseudoBox, BoxProps } from "@chakra-ui/core"
import { navigate } from "gatsby"

import Heading from "../typography/Heading"
import Body from "../typography/Body"
import { LaptopDownOnly, LaptopUpOnly } from "../../utils/MediaQueries"

const HeaderLink: React.FC<{
  label: string
  active: boolean
  onClick: VoidFunction
}> = ({ label, active, onClick }) => {
  return (
    <PseudoBox
      bg={active ? "night" : ""}
      borderRadius="0.5rem"
      border={!active ? "1px solid" : ""}
      borderColor="dividerLine"
      p="0.6875rem 0.75rem"
      marginX="0.5rem"
      cursor="pointer"
      onClick={onClick}
    >
      <LaptopDownOnly>
        <Body color={active ? "white" : "dusk"} size="sm">
          {label}
        </Body>
      </LaptopDownOnly>
      <LaptopUpOnly>
        <Body color={active ? "white" : "dusk"}>{label}</Body>
      </LaptopUpOnly>
    </PseudoBox>
  )
}

export type HeaderProps = BoxProps & {
  active: "addresses" | "orders" | "pedal-points"
}

const Header: React.FC<HeaderProps> = ({ children, active, ...props }) => {
  const onAddressesClicked = () => {
    if (active !== "addresses") navigate("/account/addresses")
  }
  const onOrdersClicked = () => {
    if (active !== "orders") navigate("/account/orders")
  }
  const onPedalPointsClicked = () => {
    if (active !== "pedal-points") navigate("/account/pedal-points")
  }
  return (
    <Box
      gridColumn={["1 / 3", null, null, null, "4 / 12"]}
      minHeight="56.25rem"
      {...props}
    >
      <Flex w="100%" mb="1.625rem" justifyContent="center">
        <Heading size="4" fontWeight="bold" color="night">
          Account
        </Heading>
      </Flex>
      <Flex
        w="100%"
        justifyContent="center"
        mb={["4.5rem", null, null, null, "7.875rem"]}
      >
        <HeaderLink
          label="Addresses"
          active={active === "addresses"}
          onClick={onAddressesClicked}
        />
        <HeaderLink
          label="Orders"
          active={active === "orders"}
          onClick={onOrdersClicked}
        />
        {/* <HeaderLink
          label="Pedal Points"
          active={active === "pedal-points"}
          onClick={onPedalPointsClicked}
        /> */}
      </Flex>
      {children}
    </Box>
  )
}

export default Header
