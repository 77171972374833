import React from "react"
import {
  Flex,
  Icon,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  PseudoBox,
} from "@chakra-ui/core"
import { useDispatch, useSelector } from "react-redux"

import PageContainer from "../../components/PageContainer"
import UserOnlyAccessWrapper from "../../components/Account/UserOnlyAccessWrapper"
import Header from "../../components/Account/Header"
import MaxWidthGrid from "../../components/Layout/MaxWidthGrid"
import Body from "../../components/typography/Body"
import { CustomerAddress } from "../../redux/types/UserTypes"
import { Button } from "../../components/Buttons"
import AddressForm from "../../components/Account/AddressForm"
import Heading from "../../components/typography/Heading"
import { LaptopUpOnly, LaptopDownOnly } from "../../utils/MediaQueries"
import { BaseDispatch, BaseRootState } from "../../redux/store"
import IconButton from "../../components/Buttons/IconButton"

const AddressBlock: React.FC<CustomerAddress & { onEdit: VoidFunction }> = ({
  onEdit,
  ...props
}) => {
  return (
    <Flex
      p="0.625rem 1rem"
      border="1px solid"
      borderColor="dividerLine"
      borderRadius="0.5rem"
      flexDirection="row"
      w="100%"
      m="0.5rem 0"
    >
      <Flex flexDir="column" flexGrow={1}>
        <Body color="dusk">{props.address1},</Body>
        {props.address2 && <Body color="dusk">{props.address2},</Body>}
        <Body color="dusk">{`${props.city} ${props.zip} ${
          props.province ?? ""
        }`}</Body>
      </Flex>

      <IconButton
        icon="pencil"
        size="1rem"
        px="0.5rem"
        aria-label="Edit Address"
        onClick={onEdit}
      />
    </Flex>
  )
}

const AddressPage = ({ ...props }) => {
  const { defaultAddress, addresses } = useSelector(
    (state: BaseRootState) => state.user
  )
  const dispatch = useDispatch<BaseDispatch>()

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure()

  // Edit Address State
  const [editAddressId, setEditAddressId] = React.useState<string | undefined>(
    undefined
  )

  React.useEffect(() => {
    dispatch.sidebar.setIsOpen(false)
    dispatch.user.getShippingCountries()
    dispatch.user.refreshUserData()
  }, [])

  const onEditAddress = (addressId: string) => {
    setEditAddressId(addressId)
    onModalOpen()
  }
  const onNewAddress = () => {
    setEditAddressId(undefined)
    onModalOpen()
  }

  return (
    <PageContainer>
      <MaxWidthGrid minHeight="43.75rem">
        <UserOnlyAccessWrapper>
          <Header active={"addresses"}>
            <Flex flexDirection={["column", null, null, null, "row"]}>
              <Flex flexDirection="column" flex={1}>
                <Body fontWeight="bold" color="night" mb="0.75rem">
                  Default Address
                </Body>
                {defaultAddress && (
                  <AddressBlock
                    {...defaultAddress}
                    onEdit={() => {
                      onEditAddress(defaultAddress.id)
                    }}
                  />
                )}
                {!defaultAddress && (
                  <Body m="1rem" size="sm" color="dusk">
                    No Default Address Set
                  </Body>
                )}
              </Flex>
              <LaptopUpOnly>
                <Box
                  minH="16.25rem"
                  mt="-2.375rem"
                  w="1px"
                  bg="dividerLine"
                  marginX="3.75rem"
                />
              </LaptopUpOnly>
              <Flex flexDirection="column" flex={1}>
                <Body fontWeight="bold" color="dawn" mb="0.75rem">
                  Other Addresses
                </Body>
                {addresses
                  ?.filter((address) => address.id !== defaultAddress?.id)
                  ?.map((address) => (
                    <AddressBlock
                      key={address.id}
                      {...address}
                      onEdit={() => {
                        onEditAddress(address.id)
                      }}
                    />
                  ))}
                {(!addresses || addresses?.length === 0) && (
                  <Body m="1rem" size="sm" color="dusk">
                    No Addresses Added
                  </Body>
                )}
              </Flex>
            </Flex>
            <Flex w="100%" justifyContent="center" mt="3.25rem">
              <Button onClick={onNewAddress}>Add New Address</Button>
            </Flex>
            <Modal isOpen={isModalOpen} onClose={onModalClose}>
              <ModalOverlay />
              <ModalContent
                maxWidth="62.5rem"
                p={[null, null, null, null, "2rem"]}
                borderRadius="0.5rem"
                marginY={["0", null, null, null, "3.75rem"]}
              >
                <Flex
                  h={["100vh", null, null, null, "auto"]}
                  flexDir="column"
                  alignItems="center"
                >
                  <LaptopUpOnly>
                    <Flex w="100%" justifyContent="flex-end">
                      <PseudoBox
                        p="0.25rem"
                        borderRadius="0.5rem"
                        _hover={{ bg: "dividerLine" }}
                        onClick={onModalClose}
                      >
                        <Icon name="x" size="2rem" color="dawn" />
                      </PseudoBox>
                    </Flex>
                  </LaptopUpOnly>
                  <Flex
                    h="100%"
                    w="100%"
                    flexDir="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <LaptopDownOnly>
                      <Flex
                        w="100%"
                        h="3.875rem"
                        p="2rem"
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottom="1px solid"
                        borderBottomColor="dividerLine"
                        mb="1.25rem"
                      >
                        <PseudoBox
                          w="2.75rem"
                          h="2.75rem"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="0.5rem"
                          _active={{ bg: "dividerLine" }}
                          onClick={onModalClose}
                        >
                          <Icon name="chevron" size="1.25rem" color="dusk" />
                        </PseudoBox>
                        <Heading size="6" color="dusk" fontWeight="bold">
                          {editAddressId === undefined
                            ? "New Address"
                            : "Edit Address"}
                        </Heading>
                        <Box w="2.75rem" />
                      </Flex>
                    </LaptopDownOnly>
                    <LaptopUpOnly>
                      <Heading
                        size="4"
                        color="night"
                        fontWeight="bold"
                        mb="3.625rem"
                      >
                        {editAddressId === undefined
                          ? "New Address"
                          : "Edit Address"}
                      </Heading>
                    </LaptopUpOnly>
                    <AddressForm
                      addressId={editAddressId}
                      onSubmitComplete={onModalClose}
                    />
                  </Flex>
                </Flex>
              </ModalContent>
            </Modal>
          </Header>
        </UserOnlyAccessWrapper>
      </MaxWidthGrid>
    </PageContainer>
  )
}
export default AddressPage
