import { Flex } from "@chakra-ui/core"
import React from "react"
import { useSelector } from "react-redux"

import { BaseRootState } from "../../redux/store"
import { isValidUserLoggedIn } from "../../utils/auth"
import AuthContainer from "./AuthContainer"

const UserOnlyAccessWrapper: React.FC = ({ children }) => {
  const { tokenExpiration, accessToken } = useSelector(
    (state: BaseRootState) => state.user
  )

  return (
    <>
      {isValidUserLoggedIn({ accessToken, tokenExpiration }) ? (
        children
      ) : (
        <Flex
          align="center"
          justify="center"
          gridColumn="1/end"
          textAlign="center"
          minH="55vh"
        >
          <AuthContainer p="0" />
        </Flex>
      )}
    </>
  )
}

export default UserOnlyAccessWrapper
